/* eslint-disable no-cond-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
export const loadToltScript = () => {
  (() => {
    // eslint-disable-next-line prefer-const
    let a = 'https://58qr5yci46.execute-api.us-east-1.amazonaws.com/v1',
      s: null = null,
      d: boolean | null = null;
    function e(e: string, t: unknown) {
      r.push({ command: e, data: t });
    }
    let r: any = [];
    (window.tolt_referral = null), (window.tolt = window.tolt || ((r = []), (e.queue = r), e));
    const i = (t: string, e: unknown) => {
      ((t, r) => {
        let a = 0;
        const n = setInterval(() => {
          if (typeof t === 'string') {
            const elements: any = e as HTMLCollectionOf<Element>;
            (0 < elements.length || 10 <= a) && (clearInterval(n), 0 < elements.length) && r(elements);
          }
          a++;
          const elements: any = e as HTMLCollectionOf<Element>;
          (0 < elements.length || 10 <= a) && (clearInterval(n), 0 < elements.length) && r(elements), a++;
        }, 500);
      })(
        {
          payment_links: e ? `a[href^="${e}"], a[href^="https://buy.stripe.com"]` : 'a[href^="https://buy.stripe.com"]',
          pricing_table: 'stripe-pricing-table',
          buy_button: 'stripe-buy-button',
        }[t],
        (e: (string | unknown[]) & HTMLCollectionOf<Element>) => {
          ('payment_links' === t
            ? (e: string | unknown[]) => {
                if (window.tolt_referral && 0 < e.length)
                  for (let t = 0; t < e.length; t++) {
                    const r = e[t];
                    -1 === (r as HTMLAnchorElement).href.indexOf('client_reference_id') &&
                      (-1 === (r as HTMLAnchorElement).href.indexOf('?')
                        ? ((r as HTMLAnchorElement).href =
                            (r as HTMLAnchorElement).href + '?client_reference_id=' + window.tolt_referral)
                        : ((r as HTMLAnchorElement).href =
                            (r as HTMLAnchorElement).href + '&client_reference_id=' + window.tolt_referral));
                  }
              }
            : (e: HTMLCollectionOf<Element>) => {
                if (window.tolt_referral && 0 < e.length)
                  for (let t = 0; t < e.length; t++) {
                    const r = e[t];
                    r.hasAttribute('client-reference-id') ||
                      r.setAttribute('client-reference-id', window.tolt_referral);
                  }
              })(e);
        }
      );
    };
    function f(t: string | string[]) {
      t = ('; ' + document.cookie).split(`; ${t}=`);
      if (2 === t.length) {
        const popped = t.pop();
        if (popped) {
          const splitShifted = popped.split(';').shift();
          if (splitShifted) {
            t = decodeURIComponent(splitShifted);
          }
        }
        try {
          return JSON.parse(t as string);
        } catch (e) {
          return t;
        }
      }
    }
    async function p(
      t: unknown,
      r: { email: string; public_id: string; param?: string; code?: string | null; page?: string }
    ) {
      if (s && d)
        try {
          let e = a + `/${t}?id=` + window.tolt_referral;
          return (
            'clicks' === t && (e = a + '/' + t),
            r.email && (r.email = decodeURIComponent(r.email)),
            await (
              await fetch(e, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...r, public_id: s }),
              })
            ).json()
          );
        } catch (e) {
          console.error(`API call to ${t} failed:`, e);
        }
      else console.warn('Public ID (data-tolt) or domain is missing. API calls will not be made.');
    }
    async function o() {
      if (f('tolt_referral') || f('tolt_data')) window.tolt_referral = f('tolt_referral');
      else {
        let t: string = '',
          r: { click_id: unknown; cookie_duration: number; referral: string | null } = {
            click_id: null,
            cookie_duration: 0,
            referral: null,
          },
          a: string = '',
          n: any = null,
          i: string | number = '',
          o: Date = new Date(),
          // eslint-disable-next-line prefer-const
          l = new URLSearchParams(window.location.search);
        let e: { email: string; public_id: string; param?: string; code?: string | null; page?: string } = {
          email: '',
          public_id: s || '',
        };
        for (t of ['tolt', 'ref', 'aff', 'via', 'lmref', 'fpr', 'tap_s', 'afmc', 'f2f-ref'])
          if (l.has(t)) {
            const c = window.location.href;
            e = { public_id: s || '', email: '', param: t, code: l.get(t), page: c };
            break;
          }
        0 !== Object.keys(e).length &&
          (r = await p('clicks', e)) &&
          r.click_id &&
          ((i = r.cookie_duration || 30),
          console.log('Referral code:', r.referral),
          (a = 'tolt_referral'),
          (n = r.referral),
          // eslint-disable-next-line no-self-assign
          (i = i),
          (o = new Date()).setTime(o.getTime() + 24 * i * 60 * 60 * 1e3),
          (i = 'expires=' + o.toUTCString()),
          (document.cookie =
            'tolt_referral' === a
              ? `tolt_referral=${n};${i};path=/;domain=${d};samesite=none;secure`
              : `${a}=${encodeURIComponent(JSON.stringify(n))};${i};path=/;domain=${d};samesite=none;secure`),
          (window.tolt_referral = r.referral));
      }
    }
    async function l(e: unknown, t: { email: string; public_id: string }) {
      switch (e) {
        case 'signup':
        case 'conversion':
        case 'clicks':
          await p(e, t);
          break;
        default:
          console.warn('Unknown command:', e, t);
      }
    }
    (async () => {
      let e: any = null,
        t: HTMLScriptElement;
      (e = document.querySelector('script[data-tolt]')),
        (s = e ? e.getAttribute('data-tolt') : null)
          ? ((e = async () => {
              let e: any = null,
                t: any = null,
                // eslint-disable-next-line prefer-const
                hostname = window.location.hostname;
              d = hostname.endsWith('.local');
              const hostnameParts = hostname.split('.');
              d = hostname.endsWith('.local')
                ? 2 < hostnameParts.length
                  ? hostnameParts.slice(-2).join('.')
                  : hostname
                : f('tolt_referral');

              await o();
              for ({ command: e, data: t } of window.tolt.queue || []) await l(e, t);
              (window.tolt.queue = []),
                await 0,
                (window.tolt = async function (e: any, t: any) {
                  await l(e, { ...t, email: Array.isArray(t.email) ? t.email.join(',') : t.email });
                }),
                (window.tolt.signup = async function (e: any) {
                  await l('signup', { email: e, public_id: s || '' });
                });
              let r: string | null,
                a: unknown,
                // eslint-disable-next-line prefer-const
                n = document.querySelector('script[data-tolt]');
              n &&
                n.hasAttribute('data-stripe-type') &&
                ((r = n.getAttribute('data-stripe-type')),
                (a = n.getAttribute('data-stripe-domain')),
                r && a && i(r, a)),
                document.dispatchEvent(new Event('toltScriptLoaded'));
            }),
            ((t = document.createElement('script')).src = 'https://cdn.jsdelivr.net/npm/psl/dist/psl.min.js'),
            (t.onload = e),
            (t.onerror = () => console.error('Failed to load the PSL script')),
            document.head.appendChild(t))
          : console.warn('Public ID (data-tolt) is missing. Script initialization aborted.');
    })();
  })();
};
